$block-name: "b-main-page";

.#{$block-name} {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 120px);
	justify-content: center;
	width: 60%;

	@media (max-width: 1070px) {
		width: 100%;
	}

	&__background {
		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: var(--background);
		background-size: cover;
	}

	&__container {
		margin: 25px;
		margin-left: -20px;
		padding: 25px;
		padding-left: 45px;
		background-color: #0008;
		backdrop-filter: blur(16px);
		border: 1px solid #fff;
		border-left: none;

		@media (max-width: 1070px) {
			margin-left: 25px;
			border-left: 1px solid #fff;
		}
	}

	&__title {
		font-size: 64px;
		margin-bottom: 0;

		@media (max-width: 1070px) {
			font-size: 34px;
		}
	}

	&__description {
		font-size: 32px;
		display: flex;
		flex-direction: column;

		@media (max-width: 1070px) {
			font-size: 22px;
		}
	}

	&__status {
		margin-top: 10px;
	}
}
